import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import QuoteLayout, { Quote } from './QuoteLayout';
import SliderControls from './SliderControls';

type SliderContentProps = {
  width?: number;
  transition?: number;
  translateX?: number;
};

const SliderContent = styled.div`
  transform: translateX(-${({ translateX }: SliderContentProps) => translateX}%);
  transition: transform ease-out ${({ transition }: SliderContentProps) => transition}s;
  height: 100%;
  width: ${({ width }: SliderContentProps) => width}%;
  display: flex;
`;

const SliderWrapper = styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;

type SliderProps = {
  id?: string;
  quotes?: Quote[];
};

const Slider = ({ id, quotes }: SliderProps) => {
  const [state, setState] = useState({
    translate: 0,
    transition: 0.8,
    activeSlide: 0,
    translateWidth: quotes?.length ? 100 / quotes.length : 100,
  });

  const { translate, transition, activeSlide, translateWidth } = state;

  const nextSlide = useCallback(() => {
    if (quotes) {
      if (activeSlide === quotes.length - 1) {
        return setState({
          ...state,
          translate: 0,
          activeSlide: 0,
        });
      }

      setState({
        ...state,
        activeSlide: activeSlide + 1,
        translate: (activeSlide + 1) * translateWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, state]);

  const prevSlide = useCallback(() => {
    if (quotes) {
      if (activeSlide === 0) {
        return setState({
          ...state,
          translate: (quotes.length - 1) * translateWidth,
          activeSlide: quotes.length - 1,
        });
      }
      setState({
        ...state,
        activeSlide: activeSlide - 1,
        translate: (activeSlide - 1) * translateWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, state]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 8 * 1000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  if (!quotes?.length) return null;

  return (
    <SliderWrapper id={id}>
      <SliderContent translateX={translate} transition={transition} width={100 * quotes.length}>
        {quotes.map(
          ({ identifier, body, name, jobTitle, background, companyLogo, portrait, color }, i) => (
            <QuoteLayout
              identifier={identifier}
              key={i.toString()}
              body={body}
              name={name}
              jobTitle={jobTitle}
              background={background}
              companyLogo={companyLogo}
              portrait={portrait}
              color={color}
            />
          ),
        )}
      </SliderContent>
      <SliderControls
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        slides={quotes}
        activeIndex={activeSlide}
      />
    </SliderWrapper>
  );
};

export default Slider;
