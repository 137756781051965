import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeBackgroundSkeleton } from './TypeBackground';
import type { TypeImpacterSkeleton } from './TypeImpacter';
import type { TypeLinkSkeleton } from './TypeLink';
import type { TypeOrientedMediaSkeleton } from './TypeOrientedMedia';

export interface TypeTestimonialFields {
  identifier: EntryFieldTypes.Symbol;
  name: EntryFieldTypes.Symbol;
  jobTitle?: EntryFieldTypes.Symbol;
  color?: EntryFieldTypes.Symbol<
    | 'black'
    | 'blackDisabled'
    | 'blackPrimary'
    | 'blackSecondary'
    | 'green100'
    | 'green200'
    | 'green300'
    | 'green400'
    | 'green500'
    | 'green600'
    | 'grey01'
    | 'grey02'
    | 'grey03'
    | 'grey04'
    | 'orange100'
    | 'orange200'
    | 'orange300'
    | 'orange400'
    | 'orange500'
    | 'orange600'
    | 'purple100'
    | 'purple200'
    | 'purple300'
    | 'purple400'
    | 'purple500'
    | 'purple600'
    | 'red100'
    | 'red200'
    | 'red300'
    | 'red400'
    | 'red500'
    | 'red600'
    | 'white'
    | 'whiteDisabled'
    | 'whitePrimary'
    | 'whiteSecondary'
    | 'yellow100'
    | 'yellow200'
    | 'yellow300'
    | 'yellow400'
    | 'yellow500'
    | 'yellow600'
  >;
  mediaPortrait?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  media?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  impacter?: EntryFieldTypes.EntryLink<TypeImpacterSkeleton>;
  mediaLogo?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  mediaBackground?: EntryFieldTypes.EntryLink<TypeBackgroundSkeleton>;
  body?: EntryFieldTypes.RichText;
  link?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
  portrait?: EntryFieldTypes.AssetLink;
  companyLogo?: EntryFieldTypes.AssetLink;
  background?: EntryFieldTypes.AssetLink;
  settings?: EntryFieldTypes.Object;
}

export type TypeTestimonialSkeleton = EntrySkeletonType<TypeTestimonialFields, 'testimonial'>;
export type TypeTestimonial<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeTestimonialSkeleton, Modifiers, Locales>;

export function isTypeTestimonial<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeTestimonial<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'testimonial';
}
