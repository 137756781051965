import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconButton from '../buttons/IconButton';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';

type ArrowContentProps = {
  direction?: string;
  disabled: boolean;
  useChevron: boolean;
};

const ArrowContent = styled.div<ArrowContentProps>`
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  background: ${({ useChevron }) => (useChevron ? 'none' : colors.whitePrimary)};
  box-sizing: border-box;
  margin: ${spacing[0]}px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 'none' : 0.6)};
  }

  img {
    transform: translateX(${({ direction }) => (direction === 'left' ? '-2' : '2')}px);
  }
`;

const iconStyle = css`
  height: 50px;
  width: 50px;
`;

type ArrowProps = {
  direction: string;
  handleClick?: () => void;
  disabled?: boolean;
  useChevron?: boolean;
  stroke?: string;
  iconSize?: number;
};

export const Arrow = ({
  direction,
  handleClick,
  disabled = false,
  useChevron = false,
  stroke,
  iconSize,
}: ArrowProps) => (
  <ArrowContent direction={direction} disabled={disabled} useChevron={useChevron}>
    {direction === 'right' ? (
      <IconButton
        iconName={useChevron ? 'chevron-right' : 'arrow-right'}
        ariaLabel="arrow right"
        variant="onlyIcon"
        css={iconStyle}
        disabled={disabled}
        onClick={handleClick}
        stroke={stroke}
        iconSize={iconSize}
      />
    ) : (
      <IconButton
        disabled={disabled}
        iconName={useChevron ? 'chevron-right' : 'arrow-left'}
        ariaLabel="arrow left"
        variant="onlyIcon"
        css={
          iconStyle && useChevron
            ? css`
                transform: rotate(180deg);
              `
            : null
        }
        onClick={handleClick}
        stroke={stroke}
        iconSize={iconSize}
      />
    )}
  </ArrowContent>
);

type DotProps = {
  active?: boolean;
  isLight?: boolean;
  useChevron?: boolean;
};

const Dot = styled.span<DotProps>`
  padding: ${spacing[0]}px;
  margin: 0 ${spacing[2]}px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ useChevron, active }) =>
    useChevron ? (active ? colors.black : colors.grey03) : active ? colors.white : colors.black};
  border: ${({ useChevron, isLight }) =>
    useChevron ? null : `1px solid ${isLight ? colors.black : colors.white}`};
`;

const DotsWrapper = styled.div`
  margin: 0 ${spacing[6]}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DotsProps = {
  slides?: unknown[];
  activeIndex?: number;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  onLight?: boolean;
  useChevron?: boolean;
};

export const Dots = ({
  slides,
  activeIndex,
  setActiveIndex,
  onLight,
  useChevron = false,
}: DotsProps) => (
  <DotsWrapper>
    {slides?.map((value, index) => (
      <Dot
        key={index}
        active={activeIndex === index}
        isLight={onLight}
        onClick={() => (setActiveIndex ? setActiveIndex(index) : {})}
        useChevron={useChevron}
      />
    ))}
  </DotsWrapper>
);

const ControlsBlock = styled.div`
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 100%;
  padding: 0 ${spacing[3]}px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoints.tablet} {
    bottom: ${spacing[10]}px;
    right: 120px;
    width: auto;
    padding: 0;
  }
  ${breakpoints.desktop} {
    bottom: ${spacing[10]}px;
  }
`;

type SliderControlsProps = {
  prevSlide: () => void;
  nextSlide: () => void;
  slides: unknown[];
  activeIndex: number;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
};

const SliderControls = ({
  prevSlide,
  nextSlide,
  slides,
  activeIndex,
  setActiveIndex,
}: SliderControlsProps) => {
  return (
    <ControlsBlock>
      <Arrow direction="left" handleClick={prevSlide} />
      <Dots slides={slides} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      <Arrow direction="right" handleClick={nextSlide} />
    </ControlsBlock>
  );
};

export default SliderControls;
